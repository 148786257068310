import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import { useSelector } from "react-redux";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { daysOfWeek } from "../../config/helper-config";
import { errorHandler } from "../../helper-methods";
import { editSettingsWebinar } from "../../http/http-calls";

const EditWebinarSettingsModal = ({
  isOpen,
  data,
  toggle,
  onSuccess = () => {},
  noOfWeek,
}) => {
  const settingsData = useSelector((state) => state?.settingsData);

  const [formFields, setFormFields] = useState([
    {
      event: "Champion Digital Real Estate Webinar",
      webinarDay: "Tuesday",
      webinarTime: "18:00",
      // Intl.DateTimeFormat().resolvedOptions().timeZone
      webinarTimeZone:
        settingsData?.setting?.webinarTimeZone || "America/Los Angeles",
      isCancel: false,
    },
  ]);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const indexingPattern = (key, formIndex) => `${key}_${formIndex}`;

  const _hasWeekdayPassed = (weekdayDetails) => {
    if (noOfWeek > moment().week()) return false;
    return moment()?.day(weekdayDetails?.webinarDay)?.day() < moment()?.day();
  };

  const _setModalState = (data = null) => {
    let newFormFields = [];
    data?.forEach((eachWeekDay) => {
      newFormFields.push({
        event: eachWeekDay?.event || "Champion Digital Real Estate Webinar",
        webinarDay: eachWeekDay?.webinarDay || "Tuesday",
        webinarTime: eachWeekDay?.webinarTime || "18:00",
        // Intl.DateTimeFormat().resolvedOptions().timeZone
        webinarTimeZone:
          settingsData?.setting?.webinarTimeZone || "America/Los Angeles",
        isCancel: eachWeekDay?.isCancel || false,
        _id: eachWeekDay?._id,
      });
    });

    setFormFields(newFormFields);

    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _isWeekDayAlreadyScheduled = (checkFormIndex) => {
    const newFormFields = [...formFields];

    const isSameDayExists = newFormFields.find(
      (eachForm, formIndex) =>
        formIndex !== checkFormIndex &&
        newFormFields?.[formIndex]?.webinarDay ===
          newFormFields?.[checkFormIndex]?.webinarDay
    );

    return isSameDayExists;
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      newFormFields.forEach((eachForm, formIndex) => {
        Object.keys(eachForm)?.forEach((key, keyIndex) => {
          if (newIsDirty[indexingPattern(key, keyIndex)]) {
            switch (key) {
              case "webinarDay": {
                if (newFormFields?.[formIndex]?.[key]?.length) {
                  if (_isWeekDayAlreadyScheduled(formIndex)) {
                    newErrors[indexingPattern(key, formIndex)] =
                      "This day is already scheduled. Please select different day.";
                    isFormValid = false;
                  } else {
                    delete newErrors[indexingPattern(key, formIndex)];
                    newIsDirty[key] = false;
                  }
                } else {
                  newErrors[indexingPattern(key, formIndex)] = "*Required";
                  isFormValid = false;
                }
                break;
              }

              case "event":
              case "webinarTime": {
                if (newFormFields?.[formIndex]?.[key]?.length) {
                  delete newErrors[indexingPattern(key, formIndex)];
                  newIsDirty[indexingPattern(key, formIndex)] = false;
                } else {
                  newErrors[indexingPattern(key, formIndex)] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          }
        });
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value, index) => {
    const newFormFields = [...formFields];

    if (key === "webinarTime") {
      newFormFields[index][key] = value?._d
        ? moment(value).format("HH:mm")
        : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateFormFields({ newFormFields, newIsDirty });
    } else {
      newFormFields[index][key] = value;
    }

    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key, formIndex) => {
    const newFormFields = [...formFields];
    const newIsDirty = { ...isDirty };

    newIsDirty[indexingPattern(key, formIndex)] = true;
    setIsDirty(newIsDirty);

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _addRemoveWebinarEvent = (index) => {
    const newFormFields = [...formFields];

    if (index === 0 || index) {
      newFormFields?.splice(index, 1);
    } else {
      const newEventSchedule = {
        event: "Champion Digital Real Estate Webinar",
        webinarDay: "",
        webinarTime: "18:00",
        // Intl.DateTimeFormat().resolvedOptions().timeZone
        webinarTimeZone:
          settingsData?.setting?.webinarTimeZone || "America/Los Angeles",
        isCancel: false,
      };

      newFormFields.push(newEventSchedule);
    }

    setFormFields(newFormFields);
  };

  const _editWebinarPromise = (webinarDetails) => {
    return new Promise(async (resolve, reject) => {
      const payload = {
        event: webinarDetails?.event,
        webinarDay: webinarDetails?.webinarDay,
        webinarTime: webinarDetails?.webinarTime,
        webinarTimeZone: webinarDetails?.webinarTimeZone,
        isCancel: webinarDetails?.isCancel,
        noOfWeek,
        ...(webinarDetails?._id ? { subId: webinarDetails?._id } : {}),
      };

      await editSettingsWebinar(payload);

      resolve(true);
    });
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = [...formFields];
      const newIsDirty = { ...isDirty };

      // mark all isDirty true
      newFormFields.forEach((form, formIndex) => {
        Object.keys(form)?.forEach((key) => {
          newIsDirty[indexingPattern(key, formIndex)] = true;
        });
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const promiseArr = newFormFields?.map((eachForm) =>
        _editWebinarPromise(eachForm)
      );

      await Promise.all(promiseArr);

      onSuccess(data?._id ? true : false);
      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      _setModalState(data?.length ? data : [{}]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        scrollable
        centered
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => _closeModal()}>
          Update Webinar Settings
        </ModalHeader>

        <ModalBody>
          {formFields?.map((eachEvent, formIndex) => (
            <Row key={formIndex}>
              {/* <Col md={6}>
                <FormGroup>
                  <Label>Event</Label>
                  <Input
                    placeholder="Enter"
                    value={formFields.event}
                    onChange={(e) =>
                      _onChangeFormFields("event", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("event")}
                    disabled
                    readOnly
                  />
                  {errors.event ? (
                    <div className="form-error">{errors.event}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              <Col md={5}>
                <FormGroup>
                  <Label>Scheduled Day</Label>
                  <Input
                    type="select"
                    value={formFields?.[formIndex].webinarDay}
                    onChange={(e) =>
                      _onChangeFormFields(
                        "webinarDay",
                        e.target.value,
                        formIndex
                      )
                    }
                    onBlur={() => _onBlurFormFields("webinarDay")}
                    disabled={
                      formFields?.[formIndex]?._id &&
                      _hasWeekdayPassed(formFields[formIndex])
                    }
                  >
                    <option value={""} disabled>
                      Select
                    </option>
                    {daysOfWeek.map((each) => (
                      <option
                        key={each.value}
                        value={each.value}
                        disabled={
                          formFields?.find(
                            (eachForm) => eachForm?.webinarDay === each?.value
                          ) || _hasWeekdayPassed(formFields[formIndex])
                        }
                      >
                        {each.label}
                      </option>
                    ))}
                  </Input>
                  {errors[indexingPattern("webinarDay", formIndex)] ? (
                    <div className="form-error">
                      {errors[indexingPattern("webinarDay", formIndex)]}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={5}>
                <FormGroup>
                  <Label>Scheduled Time</Label>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Select Date & Time",
                      value: formFields?.[formIndex]?.webinarTime,
                      // onBlur: _onChangeFormFields("webinarTime"),
                      disabled:
                        formFields?.[formIndex]?._id &&
                        _hasWeekdayPassed(formFields[formIndex]),
                    }}
                    value={
                      formFields?.[formIndex]?.webinarTime
                        ? moment(formFields?.[formIndex]?.webinarTime, "HH:mm")
                        : ""
                    }
                    onChange={(e) =>
                      _onChangeFormFields("webinarTime", e, formIndex)
                    }
                    onBlur={() => _onChangeFormFields("webinarTime", formIndex)}
                    timeConstraints={{
                      minutes: {
                        step: 5,
                      },
                    }}
                    dateFormat={false}
                    timeFormat={"HH:mm"}
                  />

                  {errors[indexingPattern("webinarTime", formIndex)] ? (
                    <div className="form-error">
                      {errors[indexingPattern("webinarTime", formIndex)]}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={2}>
                {/* don't remove the label */}
                <Label className="invisible d-block">Btn</Label>
                {formIndex === 0 ? (
                  <Button
                    outline
                    color="primary"
                    className="mt-1"
                    onClick={(e) => _addRemoveWebinarEvent()}
                    disabled={formFields?.length >= 7}
                  >
                    <i className="fa fa-plus" />
                  </Button>
                ) : formFields?.[formIndex]?._id ? (
                  <></>
                ) : (
                  <Button
                    outline
                    color="primary"
                    className="mt-1"
                    onClick={(e) => _addRemoveWebinarEvent(formIndex)}
                  >
                    <i className="fa fa-minus" />
                  </Button>
                )}
              </Col>

              <Col md={6}>
                {data?.isCancel ? (
                  <>
                    <Badge className="outline" color="danger">
                      Event has been canceled.
                    </Badge>
                  </>
                ) : (
                  <FormGroup check>
                    <Input
                      id={`webinar_event_isCancel`}
                      type="checkbox"
                      checked={formFields?.[formIndex].isCancel}
                      onChange={(e) =>
                        _onChangeFormFields(
                          "isCancel",
                          e.target.checked,
                          formIndex
                        )
                      }
                      disabled={
                        formFields?.[formIndex]?._id &&
                        _hasWeekdayPassed(formFields[formIndex])
                      }
                    />
                    <Label check for={`webinar_event_isCancel`}>
                      Cancel Event
                    </Label>
                  </FormGroup>
                )}
              </Col>
            </Row>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={() => _onSave()}>
            {loading ? <i className="fa fa-spinner fa-spin" /> : null} Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditWebinarSettingsModal;
