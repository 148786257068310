import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import SvgIcons from "../../components/SvgIcons";
import { useNavigate, useParams } from "react-router-dom";
import Questionnaires from "../../components/Questionnaires";
import {
  capitalize,
  copyToClipboard,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  getFullName,
  hasPermission,
  openUrlOnNewTab,
  showToast,
} from "../../helper-methods";
import { getClientById, updateClient } from "../../http/http-calls";
import SkeletonLoading from "../../components/SkeletonLoading";
import AddClientModal from "../../components/modals/AddClientModal";
import ClientMessagesTable from "../../components/ClientMessagesTable";
import { leadsProjectStatusConfig } from "../../config/helper-config";
import LeadStatusNotes from "../../components/modals/LeadStatusNotes";
import StatusUpdate from "../../components/StatusUpdate";
import { useSelector } from "react-redux";

const ClientDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const hasPermissonToEdit = useMemo(
    () => hasPermission({ page: "clients", action: "edit" }),
    []
  );

  const [data, setData] = useState(null);
  const [estimatedCommission, setEstimatedCommission] = useState(null);

  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [addClientModal, setAddClientModal] = useState({
    isOpen: false,
    data: null,
  });
  const [leadStatusNotes, setLeadStatusNotes] = useState({
    isOpen: false,
    data: null,
  });

  const statusTimeLineFiltered = useMemo(() => {
    if (!data?.statusTimeLine?.length) return [];

    // latest status time line filtering
    let statusTimeLine = data.statusTimeLine.reduce((acc, each) => {
      const findIndex = acc.findIndex((e) => e.status === each.status);

      console.log({ acc });

      if (findIndex > -1) {
        // remove the previous time line status if exist
        acc.splice(findIndex, 1);
        console.log("after splice", { acc });
      }

      // add current time line status
      acc.push(each);

      return acc;
    }, []);

    statusTimeLine = statusTimeLine.sort((a, b) =>
      new Date(a.updateAt) < new Date(b.updateAt) ? -1 : 1
    );

    const getCurrentIndex = (status) => {
      return leadsProjectStatusConfig.findIndex(
        (each) => each.value === status
      );
    };

    const currentIndex = getCurrentIndex(data?.leadsProjectStatus);

    statusTimeLine = statusTimeLine.filter((each, index) => {
      const statusIndex = getCurrentIndex(each?.status);

      if (statusIndex <= currentIndex) return true;

      return false;
    });

    return statusTimeLine;
  }, [data?.leadsProjectStatus, data?.statusTimeLine]);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddClientModal = (isOpen = false, data = null) => {
    setAddClientModal({ isOpen, data });
  };

  const _toggleLeadStatusNotes = (
    isOpen = false,
    data = null,
    isUpdateNote = false
  ) => {
    setLeadStatusNotes({ isOpen, data, isUpdateNote });
  };

  const _onChangeStatus = async (each, status) => {
    try {
      if (!each?._id) {
        errorHandler({ reason: "Data not found" });
        return;
      }

      _manageLoadingState("status", true);

      const payload = {
        leadsProjectStatus: status,
      };

      const newData = deepClone(data);
      newData.leadsProjectStatus = payload.leadsProjectStatus;
      setData(newData);

      await updateClient({ id: each?._id, payload });

      _manageLoadingState("status", false);

      showToast("Status has been updated", "success");

      _getClientById();
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("status", false);
      _getClientById();
    }
  };

  const _getClientById = async () => {
    try {
      _manageLoadingState("data", true);

      const res = await getClientById(params?.id);

      setData(res?.lead);
      setEstimatedCommission({
        ...res?.estimatedCommission,
        domainPerPrice: res?.domainPerPrice || 0,
        storePerPrice: res?.storePerPrice || 0,
        domainSaleCommission: res?.domainSaleCommission || 0,
        storeSaleCommission: res?.storeSaleCommission || 0,
      });

      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler({ reason: "User not found" });
      _manageLoadingState("data", false);
      navigate(-1);
    }
  };

  useEffect(() => {
    _getClientById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <div className="backToPage">
          <Button color="link" onClick={() => navigate(-1)}>
            <SvgIcons type={"backArrow"} />
          </Button>
          <h2>Client Details</h2>
        </div>

        {data ? (
          <div className="d-flex">
            <Input
              type="select"
              className={`status`}
              disabled={loadingState?.status}
              value={data?.leadsProjectStatus}
              onChange={(e) => _onChangeStatus(data, e.target.value)}
            >
              {leadsProjectStatusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>

            <Button
              color="link"
              onClick={() => _toggleLeadStatusNotes(true, data, true)}
            >
              <i className="fas fa-pencil-alt" />
            </Button>
          </div>
        ) : null}
      </div>

      {loadingState?.data && !data ? (
        <SkeletonLoading type="detailPage" />
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Personal Info</CardTitle>
                  <Button
                    color="link"
                    onClick={() => _toggleAddClientModal(true, data)}
                    disabled={!hasPermissonToEdit}
                  >
                    <SvgIcons type={"edit"} />
                  </Button>
                </CardHeader>
                <CardBody>
                  <div className="infoWrap">
                    <h2>{getFullName(data?.name) || "Anonymous"}</h2>
                    <ul>
                      <li>
                        <span>Referred By</span>
                        <div className="inotItem">
                          {getFullName(data?._referBy?.name) || "N/A"}
                        </div>
                      </li>
                      <li>
                        <span>Domain</span>
                        <div className="inotItem">
                          <SvgIcons type={"count"} />

                          {data?.domainPurchased || "0"}
                        </div>
                      </li>
                      <li>
                        <span>Stores</span>
                        <div className="inotItem">
                          {data?.storePurchased || "0"}
                        </div>
                      </li>
                      <li>
                        <span>Amount Spent</span>
                        <div className="inotItem">
                          {formatCurrencyValue(data?.amountSpend || 0)}
                        </div>
                      </li>
                      <li>
                        <span>Contract Signed</span>
                        <div className="inotItem">
                          {data?.contractSigned ? "Yes" : "No"}

                          {data?.contractSigned && data?.contractLink ? (
                            <>
                              <Button
                                color="link"
                                onClick={() =>
                                  openUrlOnNewTab(data?.contractLink, false)
                                }
                              >
                                View
                              </Button>
                            </>
                          ) : null}
                        </div>
                      </li>
                      <li>
                        <span>Invoice Paid</span>
                        <div className="inotItem">
                          {data?.invoicePaid ? "Yes" : "No"}

                          {/* {data?.invoiceLink ? (
                            <>
                              <Button
                                color="link"
                                onClick={() =>
                                  openUrlOnNewTab(data?.invoiceLink, false)
                                }
                              >
                                View
                              </Button>
                            </>
                          ) : null} */}
                        </div>
                      </li>
                      <li>
                        <span>Registered for webinar</span>
                        <div className="inotItem">
                          {data?.webinarHistory?.length || 0}
                        </div>
                      </li>
                      <li>
                        <span>Phone Number</span>
                        <div className="inotItem">
                          <SvgIcons type={"phone"} />

                          {data?.phone ? (
                            <>
                              {data?.countryCode} {data?.phone}
                              <Button
                                color="link"
                                onClick={() =>
                                  copyToClipboard(
                                    `${data?.countryCode}${data?.phone}`
                                  )
                                }
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Location</span>
                        <div className="inotItem">
                          <SvgIcons type={"mapMarker"} />

                          {data?.location ? (
                            <>
                              {capitalize(data?.location)}
                              <Button
                                color="link"
                                onClick={() =>
                                  copyToClipboard(capitalize(data?.location))
                                }
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Email ID</span>
                        <div className="inotItem">
                          <SvgIcons type={"email"} />

                          {data?.email ? (
                            <>
                              {data?.email}
                              <Button
                                color="link"
                                onClick={() => copyToClipboard(data?.email)}
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>

                      <li>
                        <span>DRE Link</span>
                        <div className="inotItem">
                          <SvgIcons type={"link"} />

                          {data?.dreLink ? (
                            <>
                              {data?.dreLink}
                              <Button
                                color="link"
                                onClick={() => copyToClipboard(data?.dreLink)}
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              <Questionnaires
                leadQuestionResponse={data?.leadQuestionResponse}
              />
            </Col>

            <Col lg={12}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Progress</CardTitle>
                </CardHeader>
                <CardBody>
                  <StatusUpdate
                    leadsProjectStatus={data?.leadsProjectStatus}
                    statusTimeLine={statusTimeLineFiltered}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col md={12}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Estimated Commission</CardTitle>
                </CardHeader>
                <CardBody>
                  <ul className="estimateCard">
                    <li>
                      <h3>Domain Sale</h3>{" "}
                      <p className="amount">
                        {estimatedCommission?.domainSaleCount || 0}{" "}
                        {estimatedCommission?.domainSaleCount > 1
                          ? "domains"
                          : "domain"}{" "}
                        <span>
                          {formatCurrencyValue(
                            (estimatedCommission?.domainSaleCount || 0) *
                              estimatedCommission?.domainPerPrice
                          )}
                        </span>
                      </p>
                    </li>
                    {userType === "admin" && (
                      <li>
                        <h3>Domain commissionable amount</h3>{" "}
                        <p className="amount">
                          <span>
                            {formatCurrencyValue(
                              estimatedCommission?.domainCommissionableAmount ||
                                0
                            )}
                          </span>
                        </p>
                      </li>
                    )}
                    <li>
                      <h3>
                        Affiliate Commission{" "}
                        {estimatedCommission?._affiliate?.name
                          ? `(${getFullName(
                              estimatedCommission?._affiliate?.name
                            )})`
                          : ""}
                      </h3>{" "}
                      <p className="amount">
                        {userType === "admin" ? (
                          <>
                            {estimatedCommission?.domainSaleCommission || 0}%{" "}
                          </>
                        ) : (
                          <>9%</>
                        )}
                        <span>
                          {formatCurrencyValue(
                            estimatedCommission?._affiliate
                              ?.earningFromDomainSale || 0
                          )}
                        </span>
                      </p>
                    </li>
                    <li>
                      <h3>Store Sale</h3>{" "}
                      <p className="amount">
                        {estimatedCommission?.storeSalesCount || 0}{" "}
                        {estimatedCommission?.storeSalesCount > 1
                          ? "stores"
                          : "store"}{" "}
                        <span>
                          {formatCurrencyValue(
                            (estimatedCommission?.storeSalesCount || 0) *
                              estimatedCommission?.storePerPrice
                          )}
                        </span>
                      </p>
                    </li>
                    {userType === "admin" && (
                      <li>
                        <h3>Store commissionable amount</h3>{" "}
                        <p className="amount">
                          <span>
                            {formatCurrencyValue(
                              estimatedCommission?.storeCommissionableAmount ||
                                0
                            )}
                          </span>
                        </p>
                      </li>
                    )}
                    <li>
                      <h3>
                        Affiliate Commission{" "}
                        {estimatedCommission?._affiliate?.name
                          ? `(${getFullName(
                              estimatedCommission?._affiliate?.name
                            )})`
                          : ""}
                      </h3>{" "}
                      <p className="amount">
                        {userType === "admin" ? (
                          <>{estimatedCommission?.storeSaleCommission}%</>
                        ) : (
                          <>9%</>
                        )}
                        <span>
                          {formatCurrencyValue(
                            estimatedCommission?._affiliate?.earningFromStore ||
                              0
                          )}
                        </span>
                      </p>
                    </li>
                    <li>
                      <h3>Total Commission</h3>{" "}
                      <p className="amount">
                        {formatCurrencyValue(
                          (estimatedCommission?._affiliate
                            ?.earningFromDomainSale || 0) +
                            (estimatedCommission?._affiliate
                              ?.earningFromStore || 0)
                        )}
                      </p>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>

            {data && (
              <Col lg={12}>
                <ClientMessagesTable userId={params?.id} />
              </Col>
            )}
          </Row>

          <AddClientModal
            isOpen={addClientModal.isOpen}
            data={addClientModal.data}
            toggle={() => _toggleAddClientModal()}
            onSuccess={() => _getClientById()}
          />

          <LeadStatusNotes
            isOpen={leadStatusNotes.isOpen}
            data={leadStatusNotes.data}
            isUpdateNote={leadStatusNotes.isUpdateNote}
            toggle={() => _toggleLeadStatusNotes()}
            onSuccess={() => _getClientById()}
          />
        </>
      )}
    </>
  );
};

export default ClientDetailsPage;
