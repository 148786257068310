export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

export const PRODUCT_FORM_LINK = process.env.REACT_APP_PRODUCT_FORM_LINK;

export const STORE_PRODUCT_FORM_LINK =
  process.env.REACT_APP_STORE_PRODUCT_FORM_LINK;

export const HOME_FORM_LINK = process.env.REACT_APP_HOME_FORM_LINK;

export const WEBINAR_LINK = process.env.REACT_APP_WEBINAR_LINK;

export const DRE_PRODUCT_FORM_LINK =
  process.env.REACT_APP_DRE_PRODUCT_FORM_LINK;

export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const AWS_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_AWS_IMAGE_BUCKET_NAME;

export const DEFAULT_PROFILE_PICTURE =
  require("../assets/img/default-profile.svg").default;

export const APP_LOGO = process.env.REACT_APP_LOGO;

export const APP_NAME = process.env.REACT_APP_NAME;

export const APP_FAV_ICON = process.env.REACT_APP_FAVICON;
// Dynamically set the favicon
window.APP_FAV_ICON = APP_FAV_ICON;
