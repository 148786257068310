import React, { useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import EditWebinarSettingsModal from "../modals/EditWebinarSettingsModal";
import { weeksCountConfig } from "../../config/helper-config";
import moment from "moment";
import { formatDate } from "../../helper-methods";
import SvgIcons from "../SvgIcons";
import { useSelector } from "react-redux";

const SettingsWebinarCard = ({ getAndUpdateSettingsData = () => {} }) => {
  const settingsData = useSelector((state) => state?.settingsData);

  const [noOfWeek, setNoOfWeek] = useState(moment().week());

  const currentWeekData = useMemo(() => {
    if (!settingsData?.setting?.webinarSettings?.length) {
      return [];
    }

    const findData = settingsData?.setting?.webinarSettings?.filter(
      (each) => +each.noOfWeek === +noOfWeek
    );

    return findData?.length ? findData : [];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsData, noOfWeek]);

  const [editSettingsModal, setEditSettingsModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleEditSettingsModal = (isOpen = false, data = null) => {
    setEditSettingsModal({ isOpen, data });
  };

  return (
    <>
      <Card className="my-2">
        <CardHeader>
          <CardTitle>Webinar Settings</CardTitle>

          {noOfWeek >= moment().week() &&
          moment().isBefore(moment().week(noOfWeek).endOf("week")) ? (
            <Button
              color="link"
              onClick={() => _toggleEditSettingsModal(true, currentWeekData)}
            >
              <SvgIcons type={"edit"} />
            </Button>
          ) : null}
        </CardHeader>
        <CardBody>
          <div>
            <span style={{ fontSize: "14px" }} className="fw-500">
              Timezone :
            </span>

            <span style={{ fontSize: "14px" }} className="inotItem ms-2">
              {settingsData?.setting?.webinarTimeZone || "America/Los Angeles"}
            </span>
          </div>

          <hr style={{ color: "#d3d3d3" }} />

          <div className="d-flex align-items-center justify-content-between my-3">
            <Label>
              <i className="fa fa-calendar me-2" />
              {formatDate(
                moment().week(noOfWeek).startOf("week").add(1, "d")
              )}{" "}
              - {formatDate(moment().week(noOfWeek).endOf("week").add(1, "d"))}
            </Label>

            <Input
              type="select"
              value={noOfWeek}
              style={{ maxWidth: "150px" }}
              onChange={(e) => setNoOfWeek(e.target.value)}
            >
              {weeksCountConfig.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <hr style={{ color: "#d3d3d3" }} />

          <div className="infoWrap webinarSettings">
            <ListGroup>
              {currentWeekData?.map((eachDay) => (
                <ListGroupItem key={eachDay._id}>
                  <span>
                    Event{" "}
                    {eachDay?.isCancel ? (
                      <Badge className="outline" color="danger">
                        Canceled
                      </Badge>
                    ) : null}
                  </span>
                  <div className="inotItem">
                    Champion Digital Real Estate Webinar
                  </div>

                  <div className="d-flex mt-3">
                    <div className="w-50">
                      <span>Scheduled Day</span>
                      <div className="inotItem">
                        {eachDay?.webinarDay || "Tuesday"}
                      </div>
                    </div>

                    <div className="w-50">
                      <span>Scheduled Time</span>
                      <div className="inotItem">
                        {eachDay?.webinarTime ? (
                          <>
                            {eachDay?.webinarTime} -{" "}
                            {moment(eachDay?.webinarTime, "HH:mm")
                              .add(1, "h")
                              .format("HH:mm")}
                          </>
                        ) : (
                          "18:00 - 19:00"
                        )}
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </CardBody>
      </Card>

      <EditWebinarSettingsModal
        isOpen={editSettingsModal.isOpen}
        data={editSettingsModal.data}
        noOfWeek={noOfWeek}
        toggle={() => _toggleEditSettingsModal()}
        onSuccess={() => getAndUpdateSettingsData()}
      />
    </>
  );
};

export default SettingsWebinarCard;
