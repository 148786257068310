export const daysOfWeekArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const daysOfWeek = [
  { label: "Monday", value: "Monday", index: 0 },
  { label: "Tuesday", value: "Tuesday", index: 1 },
  { label: "Wednesday", value: "Wednesday", index: 2 },
  { label: "Thursday", value: "Thursday", index: 3 },
  { label: "Friday", value: "Friday", index: 4 },
  { label: "Saturday", value: "Saturday", index: 5 },
  { label: "Sunday", value: "Sunday", index: 6 },
];

export const dateConfig = [
  { label: "Last 7 Days", value: "last7days" },
  { label: "Last 4 Weeks", value: "last4weeks" },
  { label: "Last Month", value: "lastmonth" },
  { label: "Last 3 Months", value: "last3months" },
  { label: "Last 12 Months", value: "last12months" },
  { label: "Last Year", value: "lastyear" },
];

export const dealsConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-10", value: { min: 1, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const goalsConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-10", value: { min: 1, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const referredLeadCountConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-10", value: { min: 1, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const membersCountConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-10", value: { min: 1, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const statusConfig = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const domainPurchasedConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-10", value: { min: 1, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const storePurchasedConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-10", value: { min: 1, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const webinarCountConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-5", value: { min: 1, max: 5 } },
  { label: "6-20", value: { min: 6, max: 20 } },
  { label: "21-50", value: { min: 21, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const amountSpendConfig = [
  { label: "$0", value: { min: 0, max: 0 } },
  { label: "$1-$100", value: { min: 1, max: 100 } },
  { label: "$101-$500", value: { min: 101, max: 500 } },
  { label: "$500+", value: { min: 500 } },
];

export const leadsProjectStatusConfig = [
  { label: "Lead", value: "Lead" },
  { label: "Registered for webinar", value: "Registered for webinar" },
  { label: "Re-registered for webinar", value: "Re-registerd for webinar" },
  { label: "Didn't attend webinar", value: "Didn't Attend Webinar" },
  { label: "Attended webinar", value: "Attended Webinar" },
  { label: "Product form submitted + Contract Sent", value: "Contract Sent" },
  { label: "Contract signed", value: "Contract Signed" },
  { label: "Invoice Paid + Commission on the way!", value: "Invoice Paid" },
];

export const categoryTopicStatus = [
  { value: false, label: "Inactive" },
  { value: true, label: "Active" },
];

export const productTypeConfig = [
  { value: "domain", label: "Domain" },
  { value: "store", label: "Store" },
];

export const permissionSettingsConfig = [
  {
    heading: { label: "Leaderboard", string: "leaderBoard" },
    values: [{ label: "View", string: "view", preset: "lite/manager" }],
  },
  {
    heading: {
      label: "Affiliates",
      string: "affiliates",
    },
    values: [
      {
        label: "View",
        string: "view",
        preset: "lite/manager",
      },
      {
        label: "Create",
        string: "create",
        preset: "lite/manager",
      },
      {
        label: "Edit",
        string: "edit",
        preset: "manager",
      },
    ],
  },
  {
    heading: {
      label: "Clients",
      string: "clients",
    },
    values: [
      {
        label: "View",
        string: "view",
        preset: "lite/manager",
      },
      {
        label: "Edit",
        string: "edit",
        preset: "manager",
      },
    ],
  },
  {
    heading: {
      label: "Learning Center",
      string: "learningCenter",
    },
    values: [
      {
        label: "View",
        string: "view",
        preset: "lite/manager",
      },
      {
        label: "Create",
        string: "create",
        preset: "lite/manager",
      },
      {
        label: "Edit",
        string: "edit",
        preset: "manager",
      },
    ],
  },
  {
    heading: {
      label: "Message Templates",
      string: "messageTemplates",
    },
    values: [
      {
        label: "View",
        string: "view",
        preset: "lite/manager",
      },
      {
        label: "Create",
        string: "create",
        preset: "lite/manager",
      },
      {
        label: "Edit",
        string: "edit",
        preset: "manager",
      },
    ],
  },
  {
    heading: {
      label: "Goals",
      string: "goals",
    },
    values: [
      {
        label: "View",
        string: "view",
        preset: "lite/manager",
      },
      {
        label: "Create",
        string: "create",
        preset: "lite/manager",
      },
    ],
  },
  {
    heading: { label: "Team Members", string: "teamMembers" },
    values: [
      { label: "View", string: "view", preset: "manager" },
      { label: "Create", string: "create" },
      { label: "Edit", string: "edit" },
    ],
  },
];

export const leaderboardVisibilitySettings = [
  { label: "Affiliate Name", value: "affiliateName" },
  { label: "Product Sales", value: "productSales" },
  { label: "Earnings", value: "earnings" },
  { label: "Deals", value: "deals" },
  { label: "Goals Achieved", value: "goalsAchieved" },
];

export const videoResolutionsConfig = {
  threeTwoZero: 320,
  fiveFourZero: 540,
  sevenTwoZero: 720,
  oneZeroEightZero: 1080,
};

export const bitrateConfig = {
  fiveFourZero: 200000,
  oneZeroEightZero: 400000,
  sevenTwoZero: 800000,
  threeTwoZero: 1500000,
};

export const settingConfig = {
  Store: [
    { name: "storePerPrice", value: "cost" },
    { name: "storeSaleCommission", value: "commissionableValue" },
    // { name: "storeAffiliateCommision", value: "affiliateShare" },
    // { name: "storeOwnerCommision", value: "ownerShare" },
  ],
  Domain: [
    { name: "domainPerPrice", value: "cost" },
    { name: "domainSaleCommission", value: "commissionableValue" },
    // { name: "domainAffiliateCommision", value: "affiliateShare" },
    // { name: "domainOwnerCommision", value: "ownerShare" },
  ],
};

export const timesConfig = [
  { label: "12 AM", value: "0" },
  { label: "1 AM", value: "1" },
  { label: "2 AM", value: "2" },
  { label: "3 AM", value: "3" },
  { label: "4 AM", value: "4" },
  { label: "5 AM", value: "5" },
  { label: "6 AM", value: "6" },
  { label: "7 AM", value: "7" },
  { label: "8 AM", value: "8" },
  { label: "9 AM", value: "9" },
  { label: "10 AM", value: "10" },
  { label: "11 AM", value: "11" },
  { label: "12 PM", value: "12" },
  { label: "1 PM", value: "13" },
  { label: "2 PM", value: "14" },
  { label: "3 PM", value: "15" },
  { label: "4 PM", value: "16" },
  { label: "5 PM", value: "17" },
  { label: "6 PM", value: "18" },
  { label: "7 PM", value: "19" },
  { label: "8 PM", value: "20" },
  { label: "9 PM", value: "21" },
  { label: "10 PM", value: "22" },
  { label: "11 PM", value: "23" },
];

export const timezoneConfig = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar es Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San Juan",
  "America/Argentina/San Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos Aires",
  "America/Cambridge Bay",
  "America/Campo Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Ciudad Juarez",
  "America/Coral Harbour",
  "America/Costa Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El Salvador",
  "America/Ensenada",
  "America/Fort Nelson",
  "America/Fort Wayne",
  "America/Fortaleza",
  "America/Glace Bay",
  "America/Godthab",
  "America/Goose Bay",
  "America/Grand Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox IN",
  "America/Kralendijk",
  "America/La Paz",
  "America/Lima",
  "America/Los Angeles",
  "America/Louisville",
  "America/Lower Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North Dakota/Beulah",
  "America/North Dakota/Center",
  "America/North Dakota/New Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port of Spain",
  "America/Porto Acre",
  "America/Porto Velho",
  "America/Puerto Rico",
  "America/Punta Arenas",
  "America/Rainy River",
  "America/Rankin Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo Domingo",
  "America/Sao Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St Barthelemy",
  "America/St Johns",
  "America/St Kitts",
  "America/St Lucia",
  "America/St Thomas",
  "America/St Vincent",
  "America/Swift Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Delhi",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho Chi Minh",
  "Asia/Hong Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South Georgia",
  "Atlantic/St Helena",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken Hill",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/UTC-0",
  "Etc/UTC-1",
  "Etc/UTC-10",
  "Etc/UTC-11",
  "Etc/UTC-12",
  "Etc/UTC-2",
  "Etc/UTC-3",
  "Etc/UTC-4",
  "Etc/UTC-5",
  "Etc/UTC-6",
  "Etc/UTC-7",
  "Etc/UTC-8",
  "Etc/UTC-9",
  "Etc/UTC+0",
  "Etc/UTC+1",
  "Etc/UTC+10",
  "Etc/UTC+11",
  "Etc/UTC+12",
  "Etc/UTC+13",
  "Etc/UTC+14",
  "Etc/UTC+2",
  "Etc/UTC+3",
  "Etc/UTC+4",
  "Etc/UTC+5",
  "Etc/UTC+6",
  "Etc/UTC+7",
  "Etc/UTC+8",
  "Etc/UTC+9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle of Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];

export const weeksCountConfig = [
  { label: "Week 1", value: "1" },
  { label: "Week 2", value: "2" },
  { label: "Week 3", value: "3" },
  { label: "Week 4", value: "4" },
  { label: "Week 5", value: "5" },
  { label: "Week 6", value: "6" },
  { label: "Week 7", value: "7" },
  { label: "Week 8", value: "8" },
  { label: "Week 9", value: "9" },
  { label: "Week 10", value: "10" },
  { label: "Week 11", value: "11" },
  { label: "Week 12", value: "12" },
  { label: "Week 13", value: "13" },
  { label: "Week 14", value: "14" },
  { label: "Week 15", value: "15" },
  { label: "Week 16", value: "16" },
  { label: "Week 17", value: "17" },
  { label: "Week 18", value: "18" },
  { label: "Week 19", value: "19" },
  { label: "Week 20", value: "20" },
  { label: "Week 21", value: "21" },
  { label: "Week 22", value: "22" },
  { label: "Week 23", value: "23" },
  { label: "Week 24", value: "24" },
  { label: "Week 25", value: "25" },
  { label: "Week 26", value: "26" },
  { label: "Week 27", value: "27" },
  { label: "Week 28", value: "28" },
  { label: "Week 29", value: "29" },
  { label: "Week 30", value: "30" },
  { label: "Week 31", value: "31" },
  { label: "Week 32", value: "32" },
  { label: "Week 33", value: "33" },
  { label: "Week 34", value: "34" },
  { label: "Week 35", value: "35" },
  { label: "Week 36", value: "36" },
  { label: "Week 37", value: "37" },
  { label: "Week 38", value: "38" },
  { label: "Week 39", value: "39" },
  { label: "Week 40", value: "40" },
  { label: "Week 41", value: "41" },
  { label: "Week 42", value: "42" },
  { label: "Week 43", value: "43" },
  { label: "Week 44", value: "44" },
  { label: "Week 45", value: "45" },
  { label: "Week 46", value: "46" },
  { label: "Week 47", value: "47" },
  { label: "Week 48", value: "48" },
  { label: "Week 49", value: "49" },
  { label: "Week 50", value: "50" },
  { label: "Week 51", value: "51" },
  { label: "Week 52", value: "52" },
];

export const nextWebinarDay = {
  Monday: "Tuesday",
  Tuesday: "Wednesday",
  Wednesday: "Thursday",
  Thursday: "Friday",
  Friday: "Saturday",
  Saturday: "Sunday",
  Sunday: "Monday",
};
